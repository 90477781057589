import React from 'react';
import HomePage from '../pages/Homepage';

class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <HomePage />
      </React.Fragment>
    )
  }
}

export default App
