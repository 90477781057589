// images
const images = {
  randomUser1: {image: '/random-users/random-user-1.jpg'},
  randomUser2: {image: '/random-users/random-user-2.jpg'},
  randomUser3: {image: '/random-users/random-user-3.jpg'},
  randomUser4: {image: '/random-users/random-user-4.jpg'},
  randomUser5: {image: '/random-users/random-user-5.jpg'},
  randomUser6: {image: '/random-users/random-user-6.jpg'},
  randomUser7: {image: '/random-users/random-user-7.jpg'},
  randomUser8: {image: '/random-users/random-user-8.jpg'},
  randomUser9: {image: '/random-users/random-user-9.jpg'}
};

export default images;
