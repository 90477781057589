import React from 'react';

class Wip extends React.Component {
  render() {
    return (
      <div className='wip'>
        <h3>This page is Work in Progress!!!</h3>
      </div>
    )
  }
}

export default Wip
